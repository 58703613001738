<template xmlns:el-col="http://www.w3.org/1999/html">
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    <i class="el-icon-lx-cascades"></i> <span class="link"
                                                              @click="$router.push('/app/version')">版本列表</span> \ 版本发布
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <el-form ref="form" :rules="rules" :model="form" label-width="100px" aria-autocomplete="none">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="App应用" prop="appId">
                            <span v-if="form.state===1">{{common.arrayFindReturn2(bases.appIds,form.appId,"other","name")+"("+form.appId+")"}}</span>
                            <el-select v-else v-model="form.appId" class="form-item" placeholder="选择应用">
                                <el-option :key="item.other" :label="item.name+'('+item.other+')'" :value="item.other"
                                           v-for="item in bases.appIds"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="App类型" prop="type">
                            <span v-if="form.state===1">{{common.arrayFindReturn2(bases.appTypes,form.type,"other","name")+"("+form.type+")"}}</span>
                            <el-select v-else v-model="form.type" class="form-item" placeholder="选择类型">
                                <el-option :key="item.other" :label="item.name+'('+item.other+')'" :value="item.other"
                                           v-for="item in bases.appTypes"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="版本名称" prop="version">
                            <span v-if="form.state===1">{{form.version}}</span>
                            <el-input v-else class="form-item" v-model="form.version" placeholder="版本名称"
                                      :clearable="true"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="版本号" prop="versionNumber">
                            <span v-if="form.state===1">{{form.versionNumber}}</span>
                            <el-input-number :min="0" v-else class="form-item" v-model="form.versionNumber"
                                             placeholder="版本号"
                                             :clearable="true"></el-input-number>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="更新内容" prop="remark">
                            <span v-if="form.state===1">{{form.remark}}</span>
                            <el-input v-else type="textarea" class="form-item full" v-model="form.remark"
                                      placeholder="更新备注"
                                      :clearable="true"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="form.state===1">
                    <el-col :span="12">
                        <el-form-item label="发布日期" prop="createDate">
                            <span>{{form.createDate.toDate("yyyy-MM-dd HH:mm:ss")}}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="更新日期" prop="updateDate">
                            <span>{{form.updateDate.toDate("yyyy-MM-dd HH:mm:ss")}}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="获取版本次数" prop="checkCount">
                            <span>{{form.checkCount?form.checkCount:0}}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="下载版本次数" prop="downloadCount">
                            <span>{{form.downloadCount?form.downloadCount:0}}</span>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <div style="font-weight:bold;background-color: #f5f7fa;padding: 10px 0;color: #909399;font-size: 14px;width: 100%;padding-left: 10px;height: 20px;margin-top: 5px;border: 1px solid #EEE">
                        <span>更新记录</span>
                        <el-button v-if="hasAuth('SaveAppVersion','UpdateAppVersion')"
                                   type="text"
                                   icon="el-icon-refresh"
                                   class="refresh-btn"
                                   @click="getAppVersionHistory"
                        >刷新
                        </el-button>
                    </div>
                </el-row>
                <el-row>
                    <div style="width:100%">
                        <el-table
                                ref="table"
                                :data="tableData"
                                border
                                class="table"
                                header-cell-class-name="table-header"
                        >
                            <el-table-column type="index" label="序号" width="55" align="center"></el-table-column>
                            <el-table-column prop="type" label="类型" align="center">
                                <template #default="scope">
                                    {{scope.row.type==='check'?'获取版本':'下载版本'}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="clientVersion" label="客户端版本" align="center">
                                <template #default="scope">
                                    {{scope.row.clientVersion||'无版本'}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="ip" label="ip" align="center"></el-table-column>
                            <el-table-column prop="address" label="地址" align="center">
                                <template #default="scope">
                                    {{scope.row.address||'无'}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="createDate" label="时间" align="center" width="160">
                                <template #default="scope">
                                    <span v-if="scope.row.createDate">{{scope.row.createDate.toDate('yyyy-MM-dd HH:mm:ss')}}</span>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div class="pagination">
                            <el-pagination
                                    background
                                    layout="total, prev, pager, next"
                                    :current-page="query.page"
                                    :page-size="query.rows"
                                    :total="pageTotal"
                                    @current-change="handlePageChange"
                            ></el-pagination>
                        </div>
                    </div>
                </el-row>
                <br/>
                <el-row>
                    <el-col :span="24">
                        <el-upload
                                class="upload-item"
                                drag
                                :disabled="form.state===1"
                                show-file-list
                                :file-list="form.versionPackage"
                                :limit="1"
                                :action="$store.state.serviceUrl+'/file/pre/upload'"
                                :headers="getUploadHeader"
                                :on-success="(res,file)=>{handleAvatarSuccess(res,file,'versionPackageId')}">
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text" v-if="form.state===1">更新包</div>
                            <div class="el-upload__text" v-else>将更新包拖到此处，或<em>点击上传</em></div>
                            <template #tip>
                                <div class="el-upload__tip">更新包:不超过 100MB</div>
                            </template>
                        </el-upload>
                    </el-col>
                </el-row>
                <br/>
                <el-row>
                    <el-col :span="24">
                        <div style="text-align: right">
                            <el-button v-if="hasAuth('SaveAppVersion','UpdateAppVersion') && form.state===0"
                                       type="primary"
                                       icon="el-icon-upload"
                                       @click="saveEdit()"
                            >保存
                            </el-button>
                            <el-button v-if="hasAuth('AuditAppVersion') && form.id && form.state===0"
                                       type="success"
                                       icon="el-icon-success"
                                       @click="audit(1)"
                            >确认发布
                            </el-button>
                            <el-button v-if="hasAuth('DisAuditAppVersion') && form.id && form.state===1"
                                       type="warning"
                                       icon="el-icon-error"
                                       @click="audit(0)"
                            >撤回发布
                            </el-button>
                        </div>
                    </el-col>
                </el-row>

            </el-form>
        </div>
    </div>
</template>

<script>
    import http from "../../utils/http";
    import common from "../../utils/common";
    import store from "../../store";
    import api from "../../utils/api";

    export default {
        name: "AppUpdate",
        data() {
            return {
                hasAuth: common.hasAuth,
                common: common,
                tableData: [],
                pageTotal: 0,
                query: {page: 1, rows: 20, versionId: null},
                form: {},
                loading: false,
                apps: [],
                app: {id: null},
                rules: {
                    version: [{required: true, message: "请填写版本名称", trigger: "blur"}],
                    versionNumber: [{required: true, message: "请填写版本号", trigger: "blur"}],
                    versionPackage: [{required: true, message: "还未上传更新包", trigger: "blur"}],
                },
                bases: {},
            };
        },
        created() {
            this.init();
            api.getBase("app_id", (data) => {
                this.bases.appIds = data;
            })
            api.getBase("app_type", (data) => {
                this.bases.appTypes = data;
            })
        },
        methods: {
            init() {
                this.app.id = this.$route.query.appId;
                if (this.app.id) {
                    this.app.id = parseInt(this.app.id);
                }
                this.form.id = this.$route.query.id;
                if (this.form.id) {
                    //id不为空时
                    this.getData(this.form.id);
                } else {
                    this.getAppVersionHistory();
                    this.form.state = 0
                }
            },
            //获取数据
            getData(id) {
                http.post("/app/version/get", {id: id}).then(data => {
                    if (data.code === this.$store.state.ResultCode.OK) {
                        this.form = data.data;
                        if (!this.form) {
                            return;
                        }
                        if (this.form.versionPackage instanceof Object) {
                            this.form.versionPackage = [this.form.versionPackage];
                        }
                        if (this.form.versionPackage && this.form.versionPackage.length > 0) {
                            let files = [];
                            for (let i = 0; i < this.form.versionPackage.length; i++) {
                                files.push(this.form.versionPackage[i].fid);
                            }
                            this.form.versionPackageId = files.join(",");
                        }
                        this.getAppVersionHistory();
                        //获取统计
                        this.getAppVersionHistoryStat();
                        return;
                    }
                    this.$message.error(data.msg);
                }).catch(e => {
                    this.$message.error(e.message);
                })
            },
            //获取版本更新列表
            getAppVersionHistory() {
                if (!this.form.id) {
                    this.tableData = [];
                    this.pageTotal = 0;
                    return;
                }
                this.query.versionId = this.form.id;
                http.post("/app/version/history/list", this.query, {animation: store.state.Animation.NONE}).then(data => {
                    data = data.data;
                    if (data) {
                        this.tableData = data.rows;
                        this.pageTotal = data.total;
                    }
                }).catch(e => {
                    this.$message.error(e.message);
                })
            },
            //获取版本统计
            getAppVersionHistoryStat() {
                http.post("/app/version/history/stat", {versionId:this.form.id}, {animation: store.state.Animation.NONE}).then(data => {
                    data = data.data;
                    if (data) {
                        for (let i = 0; i < data.length; i++) {
                            if(data[i].name==='check'){
                                this.form.checkCount = data[i].count;
                            }else if(data[i].name==='download'){
                                this.form.downloadCount = data[i].count;
                            }
                        }
                    }
                }).catch(e => {
                    this.$message.error(e.message);
                })
            },
            // 保存编辑
            saveEdit() {
                //获取选中客户端
                this.$refs.form.validate(valid => {
                    if (valid) {
                        http.post(this.form.id ? '/app/version/update' : '/app/version/save', this.form, {
                            alertError: true,
                            headers: {"Content-Type": 'application/json;charset=utf-8'},
                        }).then(data => {
                            if (data.code === this.$store.state.ResultCode.OK) {
                                this.$message.success(`操作成功`);
                                if (!this.form.id) {
                                    this.$router.push({path: '/app/version/edit', query: {id: data.data.id}})
                                    return;
                                }
                                this.getData(data.data.id);
                                return;
                            }
                            this.$message.error(data.msg);
                        }).catch(e => {
                            this.$message.error(e.message);
                        })
                    }
                });

            },
            // 确认
            audit(state) {
                this.$confirm(state ? "确认要发布版本吗？" : "确认要撤回版本吗？", "提示", {
                    type: "warning",
                })
                    .then(() => {
                        http.post(state ? '/app/version/audit' : '/app/version/disAudit', {id: this.form.id}, {alertError: true}).then(data => {
                            if (data.code === this.$store.state.ResultCode.OK) {
                                this.$message.success(`操作成功`);
                                this.init();
                                return;
                            }
                            this.$message.error(data.msg);
                        }).catch(e => {
                            this.$message.error(e.message);
                        })
                    })
                    .catch(() => {
                    });
            },
            //上传完成
            handleAvatarSuccess(res, file, type) {
                if (res.code === this.$store.state.ResultCode.OK) {
                    this.form[type] = res.data[0];
                } else {
                    file.status = 'error';
                    this.$message.error(res.msg);
                }
            },
            // 分页导航
            handlePageChange(val) {
                this.query.page = val;
                this.getAppVersionHistory();
            },
        },
        computed: {
            //获取上传请求头
            getUploadHeader() {
                return {tk: localStorage.getItem('tk')}
            }
        },
        watch: {
            $route() {
                if (this.$route.name === "EditAppVersion") {
                    //初始化
                    this.init();
                }
            }
        },
    };
</script>

<style scoped>
    .container {
        padding: 25px;
    }

    .table {
        width: 100%;
        font-size: 14px;
    }

    .scan-log {
        width: 100%;
        border: 1px solid #EEE;
        overflow: auto;
    }

    .refresh-btn {
        margin: 0 10px;
        min-height: auto;
        height: 20px;
        padding: 0;
    }

    .upload-item {
        width: 360px;
        display: inline-block;
        margin-left: 10px;
        float: left;
    }
</style>
